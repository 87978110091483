import React, {useContext, useEffect, useState} from 'react'

import './BasketContent.css'
import {BasketContext, Context} from "../Data/Context/Context";
import BasketItem from './BasketItem'
import {PriceBeauty} from "../Functions";
import {PG_REST_TOKEN, PG_REST_URL} from '../Data/Constants'
import CustomerModal from "../Modals/CustomerModal";
import ReactGA from "react-ga";
import {AlertContext} from '../Data/Context/Alert/AlertContext'
import {Alert} from './Alert'
import ym from 'react-yandex-metrika';

export default function BasketContent(props) {
    const [customer, setCustomer] = useState({email: '', phone: ''})
    const [customerMState, setCustomerMState] = useState(false)

    const {getUserPriceNo, getBasket, getSummary, getBasketCount, clearBasket, getUserCreds} = useContext(Context)
    const alert = useContext(AlertContext)

    function onOrderClick(e) {
        if (!(getUserCreds().user.length > 0)) {
            // Show modal window
            setCustomerMState(true)
        } else {
            onSendClick()
        }
    }

    function getCustomerModalState() {
        return customerMState
    }

    function setCustomerModalState(newState) {
        setCustomerMState(newState)
    }

    function onChangeEmail(newVal) {
        setCustomer({...customer, email: newVal})
    }

    function onChangePhone(newVal){
        setCustomer({...customer, phone: newVal})
    }

    function onSendClick() {
    // POST the basket to PostgREST API
        let siteOrder = []
        const key = Date.now()

        getBasket().forEach(element => {
             siteOrder.push({
                 key,
                 email: (getUserCreds().user.length > 0) ? (getUserCreds().user) : (customer.email),
                 phone: (getUserCreds().phone.length > 0) ? (getUserCreds().phone) : (customer.phone),
                 N_Cat: element.N_Cat,
                 Name: element.Name,
                 price: element['price' + getUserPriceNo()],
                 count: element.count,
                 sent: false
             })
            alert.show(`Заказ ${key} успешно отправлен на обработку. Вам выслана копия заказа.`, 'success')
        });

        // Send items to tab_mobileorder
        fetch(PG_REST_URL + '/tab_siteorders', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + PG_REST_TOKEN,
            },
            body: JSON.stringify(siteOrder)
        })

        ym('reachGoal', 'Order', { order_price: getSummary(), currency: 'RUB'});
        ReactGA.event({
            category: 'User',
            action: 'Order button clicked'
        });

        // Clear the basket
        clearBasket()
    }

    useEffect(() => {
        const trackingId = "UA-26878841-2"; // Replace with your Google Analytics tracking ID
        ReactGA.initialize(trackingId);
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.event({
            category: 'User',
            action: 'Basket page visited'
        });
        ym('hit', '/basket');
    }, [])

    if (getBasketCount() > 0) {
        return (
            <BasketContext.Provider value={{
                getCustomerModalState, setCustomerModalState, onChangeEmail, onChangePhone, onSendClick
            }}>
            <div className="BasketContent">
                <CustomerModal/>
                <div className="UpLine">
                    <div className="Summary">ИТОГО:</div>
                    <div className="SummaryPrice">{PriceBeauty(getSummary())}</div>
                    <div className="SendBtn" onClick={onOrderClick}>ЗАКАЗАТЬ</div>
                </div>
                {getBasket().map(item => (
                    <BasketItem key={item.key} Item={item}/>
                ))}

            </div>
                </BasketContext.Provider>
        )
    } else {
        return (
            <div className="BasketContent">
                <Alert />
                Корзина пуста
            </div>
        )
    }
}
