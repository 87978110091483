import {PriceBeauty} from "../Functions";
import PlusImg from "../Media/plus.png";
import SchemeImg from '../Media/scheme.png';
import CatalogItemStyles from "./CatalogItem.module.css";
import React, {useContext} from "react";
import {Context} from "../Data/Context/Context";

export default function CatalogItem(props) {
    const {getUserPriceNo, addBasketItem, setSchemeModalState} = useContext(Context)

    function SchemeLink(props) {
        setSchemeModalState(props.imgFileName);
    }

    return (
        <div className="AllRow">
            <div className="LeftPart">
                <li key={props.Item.id}>
                    {props.Item.Name}<br/>
                    <div className="DownRow">{props.Item.N_Cat} Цена:</div>
                    <div className="Price">{PriceBeauty(props.Item['price' + getUserPriceNo()])}</div>
                </li>
            </div>
            <div className={CatalogItemStyles.nearScheme}>На схеме:&nbsp;
                <div
                    className={CatalogItemStyles.nearSchemeNum}>{props.Item.imgNum ? props.Item.imgNum : 'нет'}
                </div>
            </div>
            <div className="RightPart">
                <img src={SchemeImg} alt="Схема детали"
                     className={props.Item.imgNum ? CatalogItemStyles.CatalogItemIcon : CatalogItemStyles.CatalogItemIconDisabled }
                     onClick={() => {
                         SchemeLink(props.Item)
                     }}/>
                <img src={PlusImg} alt="Добавить в корзину"
                     className={CatalogItemStyles.CatalogItemIcon}
                     onClick={() => {
                         addBasketItem(props.Item)
                     }}/>
            </div>
        </div>
    )
}
