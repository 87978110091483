// Import React components
import React, {useState, useEffect} from 'react';
import './App.css'
import {BrowserRouter, Route, Redirect} from 'react-router-dom'

// Import counters
import {YMInitializer} from 'react-yandex-metrika'
import YMInit from 'react-yandex-metrika/lib/init'
import ReactGA from 'react-ga';

// Import my components and modules
import {PG_REST_TOKEN, PG_REST_URL} from "./Data/Constants";
import {Context} from './Data/Context/Context'
import NavBar from "./Components/NavBar";
import CatalogContent from "./Components/CatalogContent"
import ContactsModal from "./Modals/ContactsModal";
import LoginModal from "./Modals/LoginModal";
import SchemeModal from "./Modals/SchemeModal";
import BasketContent from "./Components/BasketContent";
import {AlertState} from "./Data/Context/Alert/AlertState";

YMInit([1223162], {webvisor: true}, '2')

// Main function
function App() {
    const [Basket, setBasket] = useState([])
    const [Summary, setSummary] = useState(0)
    const [contactsMState, setContactsMState] = useState(false)
    const [loginMState, setLoginMState] = useState(false)
    const [schemeMState, setSchemeMState] = useState('');
    const [UserCreds, setUserCreds] = useState({user: '', pass: '', price: 0, email: '', phone: ''})
    const [redirector, setRedirector] = useState('')
    // const [width, setWidth] = useState(window.innerWidth);
    //
    // const onWindowResize = () => {
    //     // console.log('Resized, new width: ', window.innerWidth)
    //     setWidth(window.innerWidth);
    // }
    //
    // useEffect(() => {
    //     window.addEventListener('resize', onWindowResize)
    // }, [])

    useEffect(() => {
        const trackingId = "UA-26878841-2"; // Replace with your Google Analytics tracking ID
        ReactGA.initialize(trackingId);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    // Redirector component to redirect just by setRedirector URL setting up
    function Redirector(props) {
        if (redirector.length > 0) {
            setRedirector('')
            return <Redirect to={redirector}/>
        } else {
            return <></>
        }
    }

    // Show or hide Contacts modal window
    function setContactsModalState(newVal) {
        setContactsMState(newVal)
    }

    // get Contacts modal window state
    function getContactsModalState() {
        return contactsMState
    }

    // Show or hide Login modal window
    function setLoginModalState(newVal) {
        setLoginMState(newVal)
    }

    // get Login modal window state
    function getLoginModalState() {
        return loginMState
    }

    // Show or hide Scheme modal window
    function setSchemeModalState(newVal) {
        setSchemeMState(newVal);
    }

    // get Scheme modal window state
    function getSchemeModalState() {
        return schemeMState;
    }

    // Load UserCreds from LocalStorage once
    useEffect(() => {
        loadCreds()
            .then(creds => {
                if (creds) {
                    setUserCreds(JSON.parse(creds))
                }
            })
    }, [])

    // If Basket is changed, recount Summary
    useEffect(() => {
        setSummary(getBasketSummary())
// eslint-disable-next-line
    }, [Basket, UserCreds]) // Added UserCreds to recount Summary when user logged in

    // Load Cred info from LocalStorage
    function loadCreds() {
        const result = new Promise((resolve, reject) => {
            let aCreds = localStorage.getItem('Creds')
            if (aCreds === null) {
                reject(null)
            } else {
                resolve(aCreds)
            }
        })
        if (result) {
            return result
        } else {
            return {user: '', pass: ''}
        }
    }

    // Save Cred info into LocalStorage
    function saveCreds(creds) {
        return localStorage.setItem('Creds', JSON.stringify(creds))
    }

    // onChangeUser handler for TextInput
    function onChangeUser(text) {
        setUserCreds({...UserCreds, user: text})
    }

    // onChangePass handler for TextInput
    function onChangePass(text) {

        function sha512(str) {
            return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str)).then(buf => {
                return Array.prototype.map.call(new Uint8Array(buf), x => ('00' + x.toString(16)).slice(-2)).join('');
            });
        }

        sha512(text).then(hash => {
            setUserCreds({...UserCreds, pass: hash})
        })
    }

    // Get user from REST API and check the hash SHA512,
    // if correct, return user data, if not - undefined
    async function isAuthorized(creds) {
        const response = await fetch(PG_REST_URL + '/tab_users?email=eq.' + creds.user,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + PG_REST_TOKEN,
                }
            });
        const user = await response.json();
        if ((user.length) > 0 && (user[0].password === creds.pass)) {
            return user[0];
        } else {
            return undefined;
        }
    }

    // Login button is clicked
    function onLoginClick() {
        isAuthorized(UserCreds).then(value => {
            if (value) {
                setUserCreds({...UserCreds, price: value.PriceNo, phone: value.phone, email: value.email})
                saveCreds({...UserCreds, price: value.PriceNo, phone: value.phone, email: value.email}) // Actually it's a promise (then)
            }
        })
    }

    // Get user cridentials
    function getUserCreds() {
        return UserCreds
    }

    // Get user's price number
    function getUserPriceNo() {
        return UserCreds.price
    }

// Delete Basket item by key
    function delBasketItem(key) {
        setBasket(Basket.filter(bItem => {
            return bItem.key !== key
        }))
    }

    // Add Basket item
    function addBasketItem(bItem) {
        if (Basket.findIndex(Item => Item.id === bItem.id) === -1) {
            bItem.key = Date.now()
            bItem.count = 1
            setBasket(prev => [...prev, bItem])
        }
    }

    // Set count of item in basket
    function changeCount(key, cnt) {
        setBasket(Basket.map(bItem => {
            if (bItem.key === key) {
                bItem.count = cnt
            }
            return bItem
        }))
    }

    // Get basket items count
    function getBasketCount() {
        let Cnt = 0
        Basket.forEach(Item => {
            Cnt += Item.count
        })
        return Cnt
    }

    // Calculate basket sum
    function getBasketSummary() {
        let Sum = 0
        Basket.forEach(Item => {
            Sum += Item['price' + getUserPriceNo()] * Item.count
        })
        return Sum
    }

    // function called in children components to get basket pointer
    function getBasket() {
        return Basket
    }

    // function called in children components to get summary pointer
    function getSummary() {
        return Summary
    }

    // Clear the basket
    function clearBasket() {
        setBasket([])
    }

    // Route to /detail.pl is for search support of an old site

    // returning JSX
    return (
        <Context.Provider value={{
            setContactsModalState, getContactsModalState,
            setLoginModalState, getLoginModalState, setRedirector,
            onChangeUser, onChangePass, onLoginClick,
            getUserPriceNo, getBasketCount, addBasketItem, getBasket,
            changeCount, getSummary, delBasketItem, clearBasket,
            getUserCreds, setSchemeModalState, getSchemeModalState
        }}>
            <BrowserRouter>
                <Redirector/>
                <YMInitializer accounts={[1223162]}/>
                <AlertState>
                    <div className="app-wrapper">
                        <header className="App-Header">
                            <NavBar/>
                        </header>
                        <div className="App-Content">
                            <Route exact path='/' component={CatalogContent}/>
                            <Route path='/detail.pl' component={CatalogContent}/>
                            <Route path='/basket' component={BasketContent}/>
                            <ContactsModal/>
                            <LoginModal/>
                            <SchemeModal/>
                        </div>
                        <div className="App-Footer">
                            Разработка сайта: компания <a href="http://www.serverflot.ru" target="_blank"
                                                          rel="noopener noreferrer">"Серверный
                            флот"</a>
                        </div>
                    </div>
                </AlertState>
            </BrowserRouter>
        </Context.Provider>
    );
}

// Export App function
export default App;
