import React, {useEffect} from 'react'
import styles from './CatalogContent.module.css'
import {BehaviorSubject, from} from 'rxjs'

import GroupTreeList from './GroupTreeList'
import CatalogItemList from "./CatalogItemList";
import {mergeMap} from "rxjs/operators";

import {CatalogContext} from '../Data/Context/Context'
import {restLoadDetailItemsData} from '../Data/RestApi'

import ReactGA from "react-ga";
import ym from "react-yandex-metrika";

export let observeGID = new BehaviorSubject(0)
export let observeGIDResult = observeGID.pipe(
    mergeMap(val => from(restLoadDetailItemsData(observeGID.value)))
)

export default function CatalogContent(props) {
    const setGID = gid => (gid > 1) ? observeGID.next(gid) : false;

    useEffect(() => {
        const trackingId = "UA-26878841-2"; // Replace with your Google Analytics tracking ID
        ReactGA.initialize(trackingId);
        ReactGA.pageview(window.location.pathname + window.location.search);

        ym('hit', '/');
    }, [])

    // <GroupTreeList startGID={100000} visible={false} itemName={'Автопластик'}/>
    // <GroupTreeList startGID={200000} visible={false} itemName={'Кузовное железо'}/>

    let searchVal1 = new URLSearchParams(props.location.search).values().next().value;

    return (
        <CatalogContext.Provider value={{ setGID, searchVal1 }}>
            <div className={styles.CatalogContent}>
                <div className={styles.Index}>
                    <GroupTreeList startGID={229} visible={false} itemName={'ВАЗ 2107'}/>
                    <GroupTreeList startGID={412} visible={false} itemName={'ВАЗ 2110'}/>
                    <GroupTreeList startGID={1325} visible={false} itemName={'LADA Самара'}/>
                    <GroupTreeList startGID={10004} visible={false} itemName={'LADA Приора'}/>
                    <GroupTreeList startGID={10001} visible={false} itemName={'LADA Калина'}/>
                    <GroupTreeList startGID={10002} visible={false} itemName={'LADA Гранта'}/>
                    <GroupTreeList startGID={10003} visible={false} itemName={'NIVA'}/>
                    <GroupTreeList startGID={1322} visible={false} itemName={'LADA Ларгус'}/>
                </div>

                <div className={styles.Content}>
                    <CatalogItemList/>
                </div>
            </div>
        </CatalogContext.Provider>
    )
}
