// Common constants, like tokens, etc.
export const PG_REST_TOKEN='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiV2ViIEFQSSIsInJvbGUiOiJhcGkifQ.54ZfjX6CngBJm-LlHKAu0B10KSZd7Sf2hg6TnWEgWG8'
export const PG_REST_URL='https://rest.techno-liga74.ru'

export const IMAGES_PATH = 'images/catalog';

export const HIDE_ALERT = 'HIDE_ALERT'
export const SHOW_ALERT = 'SHOW_ALERT'

export const SEARCH_CHANGE      = 'SEARCH_CHANGE'
export const SEARCH_LOADDATA    = 'SEARCH_LOADDATA'

export const BADGE_RED  = 'red'
export const BADGE_BLUE = 'blue'
