import React, {useState} from 'react'
import {useObservable} from '../Hooks/Observables'
import {observeGIDResult} from "./CatalogContent";
import './CatalogItemList.css'
import CatalogItem from "./CatalogItem";
import Search from "./Search";

const CatalogItemList = (props) => {
    const [catalogItems, setCatalogItems] = useState([])

    useObservable(observeGIDResult, setCatalogItems)

    return (
        <div>
            <Search changeCatalogItems={setCatalogItems}/>
            <ul className="treeCSS2">
                {catalogItems.map((item) => (
                    <CatalogItem Item={item} key={item.id}/>
                ))}
            </ul>
        </div>
    )
}

export default CatalogItemList
