import React, {useContext, useEffect} from 'react'
import {YMaps, Map, Placemark} from 'react-yandex-maps';

import {Context} from '../Data/Context/Context'
import './ContactsModal.css'
import ReactGA from "react-ga";
import ym from "react-yandex-metrika";

export default function ContactsModal(props) {
    const {getContactsModalState, setContactsModalState} = useContext(Context)

    const mapData = {
        center: [55.191279, 61.417295],
        zoom: 16,
    };

    const coordinates = [
        [55.191279, 61.417295]
    ];

    useEffect(() => {
        const trackingId = "UA-26878841-2"; // Replace with your Google Analytics tracking ID
        ReactGA.initialize(trackingId);
        ReactGA.pageview(window.location.pathname + window.location.search);

        ym('hit', '/contacts');
    }, [])

    return (
        <React.Fragment>
            {getContactsModalState() && (<div className="Contacts_modal">
                <div className="Contacts_modalBody">
                    <div className="Contacts">
                        <div className="Phones">
                            <h2>Контакты</h2>
                            <table>
                                <tr>
                                    <td>Офис/Склад:</td>
                                    <td>+7 (908) 702-37-28</td>
                                </tr>
                                <tr>
                                    <td>Менеджер:</td>
                                    <td>+7 (909) 075-64-76</td>
                                </tr>
                                <tr>
                                    <td>Директор:</td>
                                    <td>+7 (909) 082-69-04</td>
                                </tr>
                            </table>
                            <br/>
                            e-mail: <a className="email" href="mailto:info@techno-liga74.ru">info@techno-liga74.ru</a>
                            <br/><br/>
                            <h2>Адрес</h2>
                            г. Челябинск<br/>
                            ул. Механическая, 115
                            <div className="contactsButton" onClick={() => setContactsModalState(false)}>Скрыть</div>
                        </div>
                        <div className="Map">
                            <YMaps>
                                <Map defaultState={mapData} width="400px" height="400px">
                                    { coordinates.map(coordinate => <Placemark geometry={coordinate}/>) }
                                </Map>
                            </YMaps>
                        </div>
                    </div>
                </div>
            </div>)}
        </React.Fragment>

    )
}
