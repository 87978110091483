import React from 'react'
import styles from "./NavBarIcon.module.css"
import {BADGE_RED, BADGE_BLUE} from "../Data/Constants"

const NavBarIconBadged = (props) => {
    let badgeClassName = styles.badge;

    switch (props.badgeStyle) {
        case BADGE_BLUE:
            badgeClassName = styles.badgeBlue
            break;
        case BADGE_RED:
            badgeClassName = styles.badgeRed
            break;
        // DEFAULT is BADGE_AUTO, when no badgeStyle is defined
        default:
            badgeClassName = (props.badgeValue > 0) ? styles.badgeBlue : styles.badgeRed
    }

    return (
        <div onClick={props.onClick} className={styles.NavBarIconBadged}>
            <NavBarIcon {...props} />
            <span className={badgeClassName}>{props.badgeValue}</span>
        </div>
    )
}

const NavBarIcon = (props) => {
    return (
        <img src={props.Image} className={styles.NavBarIcon} alt="NavBarIcon"/>
    )
}

export {NavBarIcon, NavBarIconBadged}
