import React, {useContext, useEffect} from 'react'

import {Context} from '../Data/Context/Context'
import './LoginModal.css'
import ReactGA from "react-ga";
import ym from "react-yandex-metrika";

export default function LoginModal(props) {
    const {getLoginModalState, setLoginModalState, onChangeUser, onChangePass, onLoginClick } = useContext(Context)

    useEffect(() => {
        const trackingId = "UA-26878841-2"; // Replace with your Google Analytics tracking ID
        ReactGA.initialize(trackingId);
        ReactGA.pageview(window.location.pathname + window.location.search);

        ym('hit', '/login')
    }, [])

    return (
        <React.Fragment>
            {getLoginModalState() && (<div className="modal">
                <div className="modalBody">
                    <form name="loginForm" method="POST" action="checkLogin">
                        <center>
                            <div className="header">Хочу свою цену</div>
                        </center>
                        <input className="input" placeholder="e-mail" onChange={(text) => onChangeUser(text.target.value)}/>
                        <input type="password" className="input" placeholder="Пароль"  onChange={(text) => onChangePass(text.target.value)}/>
                        <center>
                            <div className="button" type="submit" onClick={() => {
                                onLoginClick()
                                setLoginModalState(false)
                            } }>Войти
                            </div>
                        </center>
                    </form>
                </div>
            </div>)}
        </React.Fragment>

    )
}
