import React, {useContext, useEffect} from 'react'

import {Context} from '../Data/Context/Context'
import SchemeStyles from './SchemeModal.module.css'
import {IMAGES_PATH} from '../Data/Constants';

import ReactGA from "react-ga";
import ym from "react-yandex-metrika";

export default function SchemeModal(props) {
    const {getSchemeModalState, setSchemeModalState} = useContext(Context)

    useEffect(() => {
        const trackingId = "UA-26878841-2"; // Replace with your Google Analytics tracking ID
        ReactGA.initialize(trackingId);
        ReactGA.pageview(window.location.pathname + window.location.search);

        ym('hit', '/scheme');
    }, [])

    return (
        <React.Fragment>
            {getSchemeModalState() && (<div className={SchemeStyles.modal} onClick={ () => setSchemeModalState('') }>
                    <div className={SchemeStyles.modalBody}>
                        <div className={SchemeStyles.holder}>
                            <img className={SchemeStyles.holderImg} alt='' src={window.location.origin + '/' + IMAGES_PATH + '/' + getSchemeModalState()}/>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}
