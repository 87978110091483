import React, {useContext} from 'react'
import './BasketItem.css'
import {PriceBeauty} from "../Functions";
import PlusImg from "../Media/plus.png";
import MinusImg from '../Media/minus.png'
import {Context} from "../Data/Context/Context";

export default function BasketItem(props) {
    const {getUserPriceNo, changeCount, delBasketItem} = useContext(Context)

    return (
        <div className="AllRow">
            <div className="LeftPart">
                <li key={props.Item.id} className="treeCSS">
                    {props.Item.Name}<br/>
                    <div className="CatNum">{props.Item.N_Cat}</div>
                    <div className="DownRow">Цена:</div>
                    <div className="Price">{PriceBeauty(props.Item['price' + getUserPriceNo()])}</div>
                    <div className="DownRow">Количество:</div>
                    <div className="Count">{props.Item.count}</div>
                    <div className="DownRow">Сумма:</div>
                    <div className="Price">{PriceBeauty(props.Item['price' + getUserPriceNo()] * props.Item.count)}</div>
                </li>
            </div>
            <div className="RightPart">
                <img src={PlusImg} alt="Plus"
                     className="RightBtn"
                     onClick={() => {
                         changeCount(props.Item.id, props.Item.count++)
                     }}/>
                <img src={MinusImg} alt="Minus"
                     className="RightBtn"
                     onClick={() => {
                         if (props.Item.count > 1) {
                             changeCount(props.Item.id, props.Item.count--)
                         } else {
                             delBasketItem(props.Item.key)
                         }
                     }}/>
            </div>
        </div>
    )
}
