import React, {useContext, useEffect} from 'react'

import {BasketContext} from '../Data/Context/Context'
import './LoginModal.css'
import ReactGA from "react-ga";
import ym from "react-yandex-metrika";

export default function CustomerModal(props) {
//    const {getLoginModalState, setLoginModalState, onChangeUser, onChangePass, onLoginClick } = useContext(Context)
    const {getCustomerModalState, setCustomerModalState, onChangeEmail, onChangePhone, onSendClick} = useContext(BasketContext)

    useEffect(() => {
        const trackingId = "UA-26878841-2"; // Replace with your Google Analytics tracking ID
        ReactGA.initialize(trackingId);
        ReactGA.pageview(window.location.pathname + window.location.search);

        ym('hit', '/customer');
    }, [])

    return (
        <React.Fragment>
            {getCustomerModalState() && (<div className="modal">
                <div className="modalBody">
                    <form name="loginForm" method="POST" action="checkLogin">
                        <center>
                            <div className="header">Представьтесь</div>
                        </center>
                        <input className="input" placeholder="e-mail" onChange={(text) => onChangeEmail(text.target.value)}/>
                        <input className="input" placeholder="Телефон"  onChange={(text) => onChangePhone(text.target.value)}/>
                        <center>
                            <div className="button" type="submit" onClick={() => {
                                onSendClick()
                                setCustomerModalState(false)
                            } }>Отправить
                            </div>
                        </center>
                    </form>
                </div>
            </div>)}
        </React.Fragment>
    )
}
