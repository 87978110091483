import React, {useContext, useState} from 'react'
import './GroupTreeList.css'

import {CatalogContext} from "../Data/Context/Context";
import {restLoadDetailData} from "../Data/RestApi";

const LD_EMPTY = 1
const LD_LOADING = 2
const LD_LOADED = 3

const GroupTreeList = (props) => {
    const [detailGroups, setDetailGroups] = useState([])
    const [visible, setVisible] = useState(false)
    const [loaded, setLoaded] = useState(LD_EMPTY)

    const {setGID} = useContext(CatalogContext)

    // Load data by ParentID function
    function LoadDetailData(ParentID) {
        setLoaded(LD_LOADING)
        restLoadDetailData(ParentID)
            .then(detailGroups => {
                setDetailGroups(detailGroups.filter(item => item.id > 1));
                setLoaded(LD_LOADED)
            }).catch(() => setLoaded(LD_EMPTY))
    }

    if (visible && (loaded === LD_EMPTY)) {
        LoadDetailData(props.startGID)
    }

    const RenderItems = (props) => {
        if (visible) {
            return (
                <ul className="treeCSS">
                    {detailGroups.map((item) => (
                        <li key={item.id}>
                            <GroupTreeList itemName={item.Name} visible={false} startGID={item.id}/>
                        </li>))}
                </ul>
            )
        } else {
            return <></>
        }

    }

    const onGroupClick = () => {
        setGID(props.startGID)
        return (visible) ? setVisible(false) : setVisible(true)
    }

    return (
        <ul className="treeCSS">
            <li
                onClick={() => onGroupClick()}>
                <div className="ItemDiv">{props.itemName}</div>
            </li>
            <RenderItems/>
        </ul>
    )
}

export default GroupTreeList
