import React, {useContext} from 'react'
import styles from './NavBar.module.css'
import {Link} from 'react-router-dom'

import LoginImg from '../Media/login.png'
import BasketImg from '../Media/basket.png'
import LogoImg from '../Media/logo.png'

import {Context} from '../Data/Context/Context'
import {NavBarIconBadged} from "./NavBarIcon"

export default function NavBar(props) {
    const {setContactsModalState, setLoginModalState, getUserPriceNo, getBasketCount,
           setRedirector} = useContext(Context)

    return (
        <div className={styles.NavBar}>
            <div className={styles.NavLogo}>
                <img src={LogoImg} alt="Logo" height="50px"/>
                <div className={styles.NavLogoText}>Техно-лига</div>
            </div>
            <div className={styles.NavBtn} onClick={() => setContactsModalState(true)}>КОНТАКТЫ</div>
            <div className={styles.NavBtn}><Link to="/">КАТАЛОГ</Link></div>
            <NavBarIconBadged
                Image={BasketImg}
                onClick={() => setRedirector('/basket')}
                badgeValue={getBasketCount()}/>
            <NavBarIconBadged
                Image={LoginImg}
                onClick={() => setLoginModalState(true)}
                badgeValue={getUserPriceNo()}/>
        </div>
    )
}
