import {SEARCH_LOADDATA, SEARCH_CHANGE, PG_REST_URL, PG_REST_TOKEN} from '../Constants'
import {BehaviorSubject, from} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, mergeMap} from "rxjs/operators";
import {isNumeric} from "rxjs/internal-compatibility";

// Load data by Search function
function LoadSearchItemsData(searchStr) {
    let fieldStr = 'Name'
    if (isNumeric(searchStr)) {
        fieldStr = 'N_Cat'
    }
    return fetch(PG_REST_URL + '/tab_catalog?' + fieldStr + '=ilike.*' + searchStr + '*',
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + PG_REST_TOKEN,
            }
        })
        .then(response => response.json())
}

export let observeSearch = new BehaviorSubject('')
export let observeSearchResult = observeSearch.pipe(
    filter(val => val.length > 3),
    debounceTime(1500),
    distinctUntilChanged(),
    mergeMap(val => from(LoadSearchItemsData(observeSearch.value))),
)

export const searchReducer = (state, action) => {
    switch (action.type) {
        case SEARCH_CHANGE:
            state = action.payload
            observeSearch.next(state)
            return state
        case SEARCH_LOADDATA:
            return state
        default:
            return state
    }
}
