import React, {useContext, useEffect, useReducer} from 'react'

import './Search.css'
import {useObservable} from "../Hooks/Observables";
import {CatalogContext} from '../Data/Context/Context'

import {observeSearchResult, searchReducer} from '../Data/Reducers/SearchReducer'
import {SEARCH_CHANGE} from '../Data/Constants'

export default function Search(props) {
    const [search, dispatch] = useReducer(searchReducer, '')
    const { searchVal1 } = useContext(CatalogContext)

    useObservable(observeSearchResult, props.changeCatalogItems)

    useEffect(() => {
        if (searchVal1) { dispatch({type: SEARCH_CHANGE, payload: searchVal1}) };
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="Search">
            <input placeholder="Поиск по каталожному номеру или наименованию (не менее 4-х символов)" value={search} onChange={(e) => dispatch({type: SEARCH_CHANGE, payload: e.target.value})}/>
        </div>
    )
}
