// Load data by GID function
import {PG_REST_TOKEN, PG_REST_URL} from "./Constants";

export function restLoadDetailItemsData(gid) {
    return fetch(PG_REST_URL + '/tab_catalog?gid=eq.' + gid,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + PG_REST_TOKEN,
            }
        })
        .then(response => response.json())
}

// Load data by ParentID function
export function restLoadDetailData(ParentID) {
    return fetch(PG_REST_URL + '/tab_group?ParentID=eq.' + ParentID,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + PG_REST_TOKEN,
            }
        })
        .then(response => response.json())
}
