// Tools and Utilities, different functions
// eslint-disable-next-line
String.prototype.insert = function(index, string){
    if (index > 0)
    {
        return this.substring(0, index) + string + this.substring(index, this.length);
    }

    return string + this;
};

export function PriceRound(price){
    return Math.round(price * 100) / 100
}

export function PriceBeauty(price){
    let Rub = Math.floor(PriceRound(price))
    let Kop = Math.floor((PriceRound(price) - Rub) * 100)

    let s2 = '' + Rub
    for (let i = s2.length; i >= 0; i -= 3) { s2 = s2.insert(i, ' ') }
    let s = s2.trim() + ' р. ' + Kop + ' к.'

    return s
}
